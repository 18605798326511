<template>
  <b-row>
    <b-col sm="12">
      <indexFromModal ref="indexFromModal" @reload="$refs.tableCredit.reloading()" />
      <TableDataWidget
        ref="tableCredit"
        title="ข้อมูลการเติมเครดิต"
        url="/api/admin/admin-topic-credit-data"
        :columns="columns"
        editAble
        deleteAble
        newAble
        @clickNew="$refs.indexFromModal.show()"
        @clickEdit="$refs.indexFromModal.show({ ...$event })"
        @clickDelete="clickDelete"
      />
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'MutewallIndex',
  components: {
    indexFromModal: () => import('./components/index/IndexFormModal.vue'),
  },
  data() {
    return {
      columns: [
        {
          label: 'รหัสอ้างอิง',
          field: 'code',
        },
        {
          label: 'เติ่ม / บาท',
          field: 'price',
          type: 'decimal',
        },
        {
          label: 'ได้รับ / เครดิต',
          field: 'credit',
          type: 'decimal',
        },
        {
          label: '',
          field: 'action',
        },
      ],
    }
  },

  mounted() {},

  methods: {
    clickView(data) {
      // console.log(data)
    },
    async clickDelete(data) {
      const resp = await this.api.deleteV2(`/api/admin/admin-topic-credit-data/${data.id}`, this)
      if (!resp) return
      this.$refs.tableCredit.reloading()
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
</style>
